<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Child safety</h4>
              </div>
              <div v-if="settings!= null" class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="addChildSafety()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-edit"></i>
                    Update child safety
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="settings!=null && settings.has_wwcc">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Card number</th>
                  <th class="px-3">Expiry date</th>
                  <th class="px-3">Days to expire</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr >
                    <td class="px-3" v-if="settings.has_wwcc">
                      <span class="font-weight-bolder mb-1">
                       {{settings.wwcc_number}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="font-weight-bolder mb-1">
                        {{settings.formatted_wwcc_expiry_date? settings.formatted_wwcc_expiry_date:''}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="badge badge-danger mt-2 ml-4" v-if="settings.wwcc_expiry_status=='expired'">Expired</span>
                      <span class="badge badge-info mt-2 ml-4" v-else>{{settings.wwcc_expiry_date_in_month}} days</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="sendReminderMail(settings.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-envelope"></i>
                                </span>
                                <span class="navi-text">Send reminder email</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a :href="settings.wwcc_image_path" target="_blank" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-pdf"></i>
                                </span>
                                <span class="navi-text">View attachment</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="settings==null">
                    <td colspan="10" class="text-center">
                      <strong>No information available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <div v-else>No child safety information available</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-or-update-children-check ref="create-update-children-check" @refresh="getByUser"></create-or-update-children-check>
  </v-app>
</template>

<script>
import UserSettingService from "@/services/user/UserSettingService";
import CreateOrUpdateChildrenCheck from "./CreateOrUpdateChildrenCheck.vue";

const userSetting = new UserSettingService();
export default {
  name: "ExaminerChildrenCheck",
  data(){
    return{
      isLoading: true,
      examiner: null,
      settings: null,
      total: null,
      perPage: null,
      page: null,
    }
  },
  components:{
    CreateOrUpdateChildrenCheck
  },
  mounted() {
    this.examinerId = this.$route.params.examinerId;
    this.getByUser();
  },
  methods: {
    getByUser() {
      userSetting.findByUserId(this.examinerId).then(response => {
        this.settings = response.data.userSetting;
      })
          .catch(() => {})
          .finally(() =>
              this.isLoading = false
          )
    },
    sendReminderMail(id) {
      this.$confirm({
        message: `Are you sure you want to send reminder to examiner?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userSetting.reminderExpiryDate(id).then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Reminder email sent");
              }
            })
                .catch(() => {});
          }
        }
      });
    },
    addChildSafety(){
      this.$refs['create-update-children-check'].openDialog(this.settings, this.examinerId);
    }
  }
}
</script>

<style scoped>

</style>