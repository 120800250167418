<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="d-flex flex-row" v-else>
      <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
           id="kt_profile_aside">
        <div class="card card-custom card-stretch" style="border-right: 1px solid #efefef;">
          <div class="card-body pt-4">
            <side-profile :user-detail="examiner"></side-profile>

            <!--Tab-->
            <tab-list :user-detail="examiner" @updateTabIndex="updateTabIndex"></tab-list>

          </div>
        </div>
      </div>
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <ExaminerProfile
                :examiner_profile ="examiner"
                @update-examiner="getExaminerDetail"
            ></ExaminerProfile>
          </b-tab>

          <b-tab>
            <notes></notes>
          </b-tab>

          <b-tab>
            <PracticalCandidate></PracticalCandidate>
          </b-tab>

          <b-tab>
            <ExamCandidate></ExamCandidate>
          </b-tab>

          <b-tab>
            <CourseCandidate></CourseCandidate>
          </b-tab>

          <b-tab>
            <AmebAwardCandidate></AmebAwardCandidate>
          </b-tab>

          <b-tab>
            <transaction-summary></transaction-summary>
          </b-tab>

          <b-tab>
            <support-ticket></support-ticket>
          </b-tab>

          <b-tab>
            <email-activity></email-activity>
          </b-tab>

          <b-tab >
            <addresses :user_type="'accountholder'"></addresses>
          </b-tab>

          <b-tab>
            <user-role :user_role="examiner" @update="getExaminerDetail()"></user-role>
          </b-tab>

          <b-tab>
            <ExaminerSettings @update-examiner="getExaminerDetail" :user_score="examiner"></ExaminerSettings>
          </b-tab>

          <b-tab>
            <security :user_security="examiner" ></security>
          </b-tab>

          <b-tab>
            <information-log></information-log>
          </b-tab>

          <b-tab>
            <ExaminerChildrenCheck></ExaminerChildrenCheck>
          </b-tab>

        </b-tabs>
      </div>
    </div>
  </v-app>
</template>

<script>
import ExaminerProfile from "./widgets/Profile";
import ExaminerSettings from "./widgets/Settings";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import UserRole from "@/view/pages/view/user/general/role/Role";
import Security from "@/view/pages/view/user/general/security/Security";
import ExamCandidate from "@/view/pages/view/user/general/enrolments/ExamCandidate";
import CourseCandidate from "@/view/pages/view/user/general/enrolments/CourseCandidate";
import PracticalCandidate from "@/view/pages/view/user/general/enrolments/PracticalCandidate";
import Notes from "@/view/pages/view/user/general/notes/Notes";
import EmailActivity from "@/view/pages/view/user/general/email-activity/EmailActivity";
import InformationLog from "@/view/pages/view/user/general/information-log/InformationLog";
import ExaminerChildrenCheck from "@/view/pages/view/user/examiner/widgets/ExaminerChildrenCheck";
import Addresses from "@/view/pages/view/user/general/address/Address";
import SideProfile from "@/view/pages/view/user/general/side-profile/SideProfile";
import TabList from "@/view/pages/view/user/general/tab-list/TabList";
import TransactionSummary from "@/view/pages/view/user/general/transaction/TransactionSummary";
import SupportTicket from "@/view/pages/view/user/accountholder/widgets/SupportTicket";
import AmebAwardCandidate from "@/view/pages/view/user/general/enrolments/AmebAwardCandidate";

const examiner=new ExaminerService();
export default {
  name: "examiner",
  components: {
    SupportTicket,
    TransactionSummary,
    TabList,
    SideProfile,
    Addresses,
    ExaminerChildrenCheck,
    InformationLog,
    EmailActivity,
    Notes,
    ExaminerProfile,
    ExaminerSettings,
    UserRole,
    Security,
    ExamCandidate,
    CourseCandidate,
    PracticalCandidate,
    AmebAwardCandidate
  },
  data() {
    return {
      attrs:[],
      loading:true,
      tabIndex: 0,
      examiner:{},
      examiner_setting:{},
    };
  },
  mounted() {
    this.getExaminerDetail();
  },
  methods: {
    updateTabIndex(tabIndex) {
      this.tabIndex = tabIndex;
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    getExaminerDetail() {
      examiner
          .show(this.examinerId)
          .then(response => {
            this.examiner=response.data.examiner;
            this.examiner_setting=this.examiner.examiner_setting;
          })
          .finally(() => {
            this.loading = false
          })

    }
  },
  computed:{
    examinerId() {
      return this.$route.params.examinerId;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    routeTab(){
      if(this.examiner){
        let title = this.examiner.full_name + '- Examiner'
        return {
          title: title
        }
      }
    }

  }

};
</script>
